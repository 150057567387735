export default () => {
    return {
        localizables: {
            description: {},
            name: {},
        },
        name: "",
        units: [],
        asset: "",
        description: "",
        display_index: null
    }
}