<template>
    <div class="main-container">
      <h1 class="main-heading"> Faction </h1>
      <div class="content-container">
        <div class="col">
          <div class="form-container">
              <p>{{ "Language" }}</p>
              <select v-model="selectedLanguage" @change="languageChanged($event)">
                <option v-for="language in languages" v-bind:key="language.code" :value="language.code">{{ language.name }}</option>
              </select>
            </div>
          <div class="form-container">
            <p> Name </p>
            <input v-model="currentFaction.name"/>
          </div>
          <div class="form-container">
            <p> Description </p>
            <input v-model="currentFaction.description"/>
          </div>
          <div class="form-container">
            <p> Display Index </p>
            <input type="number" v-model="currentFaction.display_index"/>
          </div>
          <div class="form-container">
            <p> Localized Name </p>
            <input v-model="currentFaction.localizables.name[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p> Localized Description </p>
            <input v-model="currentFaction.localizables.description[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p> Asset </p>
            <select v-model="currentFaction.asset">
              <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
            </select>
          </div>
          <div class="form-container">
            <p> Units </p>
            <multiselect
                v-model="currentFaction.units"
                :options="unitOptions"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Select Units"
                label="name"
                track-by="id"
                :preselect-first="false"
                >
                <template v-slot:selection="{ values, isOpen }"
                    ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} units selected</span
                    ></template
                >
            </multiselect>
          </div>
        </div>
      </div>
      <div class="buttons-section">
        <button @click="submit" class="info">{{ $t('SUBMIT') }}</button>
        <template v-if="$route.query.id">
          <button v-if="env === 'staging' || env === 'prod'" class="info" @click="() => exportTo('dev')">Export to Development</button>
          <button v-if="env === 'dev' || env === 'prod'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
          <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
        </template>
  
      </div>
      <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType"/>
    </div>
  </template>
  
  <script>
  import { onMounted, computed, reactive, watch, ref, onBeforeMount, onBeforeUnmount} from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from "vue-router";
  
  import '@/lib/quillEmoji/customBlot';
  import factionSchema from "@/schemas/factions";
  
  import 'v-calendar/dist/style.css';
  
  import languageConstants from "@/constants/languagesTemp";
  import dispatchMap from "@/constants/dispatchMap";
  import { io } from "socket.io-client";
  import ConfirmPopup from '../../../components/common/ConfirmPopup.vue'
  import Multiselect from "vue-multiselect";
  
  export default {
    name: "NewsAdd",
    components: {
      Multiselect,
      ConfirmPopup
    },
    setup() {
      const store = useStore();
      const usedPages = ["factionsAdd"];
      const socket = io(process.env.VUE_APP_BACKEND_URL);
      onBeforeMount(() => {
        usedPages.forEach(page => {
            dispatchMap[page].forEach((dispatchStr) => {
              store.dispatch(dispatchStr); 
            })
        });
        }
      );
      const factions = computed(() => store.getters["factionSystem/getFactions"])
      const assetList = computed(() => {
        return [
          {
            doc_ref: undefined
          },
          ...store.getters['assets/getAssets'].files
        ]
      })
      const currentFaction = reactive({...factionSchema()})
      const route = useRoute();
      const router = useRouter();
      console.log(router)
      const previousLanguage = ref('en')
      const selectedLanguage = ref('en')
      const languages = languageConstants.languages;
      console.log(languages)
      const popupOpen = ref(false);
      const popupText = ref("This page is edited by another user. Please refresh before making changes.")
      const popupType = ref("refresh")
      const unitOptions = ref([]);
      const units = computed(() => store.getters["units/getUnits"]);
      const unitValue = ref([]);
  
      onMounted(() => {
        if (route.query.id && factions.value.length > 0) {
          const editingFaction = JSON.parse(JSON.stringify(factions.value.find(x => x.id === route.query.id)))
          Object.keys(editingFaction).forEach(key => {
            currentFaction[key] = editingFaction[key]
          })
        }
        if (!currentFaction.localizables) {
          currentFaction.localizables = { name: {}, description: {} }
        }
        store.dispatch("loader/loadingStatus", false)
  
      })
  
      watch(() => store.getters['factionSystem/getFactions'], value => {
        if (route.query.id) {
          const editingFaction = JSON.parse(JSON.stringify(value.find(x => x.id === route.query.id)))
          Object.keys(editingFaction).forEach(key => {
            currentFaction[key] = editingFaction[key]
          })
        }
      })

      watch(
      () => store.getters["units/getUnits"],
      () => {
        unitOptions.value = units.value
          .filter((x) => x.status === 1)
          .map((unit) => {
            return { id: unit.id, name: unit.name };
          });
      },
      { immediate: true }
    );

      onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
  
      const addActionButton = () => {
        currentFaction.action_buttons.push({})
      }
  
      const languageChanged = (event) => {
        previousLanguage.value = event.target.value;
      }
  
      const submit = async () => {
        if (route.query.id) {
          store.dispatch('factionSystem/updateFaction', { id: route.query.id, data: currentFaction }).then(() => {
          socket.emit("update", {
            socketId: socket.id,
            itemId: currentFaction.id,
            page: "Faction"
          });
          store.dispatch('units/updateUnitFields', {ids: currentFaction.units.map(x => x.id), data: {faction_id: currentFaction.id}});
        })
      } else {
        store.dispatch('factionSystem/addFaction', { data: currentFaction }).then(res => {
          if(res) {
            store.dispatch('units/updateUnitFields', {ids: currentFaction.units.map(x => x.id), data: {faction_id: res.id}});
            router.push({ name: 'FactionsAdd', query: { id: res.id }})
          }
        })
      }
      }
  
      const exportTo = environment => {
        store.dispatch('factionSystem/exportFaction', { id: route.query.id, data: currentFaction, environment })
      }
  
      socket.on('updated', (data) => {
        if (data.socketId != socket.id && data.itemId === currentFaction.id && data.page === "Faction"){
          popupOpen.value = true;
        }
      })
      const appConfig = computed(() => store.getters['auth/getAppConfig'])
      const env = appConfig.value.appEnv || 'dev'
  
      return {
        currentFaction,
        submit,
        exportTo,
        env,
        addActionButton,
        assetList,
        selectedLanguage,
        languages,
        languageChanged,
        popupText,
        popupOpen,
        popupType,
        unitOptions,
        unitValue
      }
    }
  }
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.css"></style>
  <style scoped>
  .main-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;
  }
  
  .content-container {
    width: 100%;
    display: grid;
    grid-template-columns: 6fr 4fr;
  }
  
  .form-container {
    display: flex;
    margin: 15px 0;
  }
  
  .form-container p {
    width: 60%;
    text-align: start;
  }
  .form-container input, .form-container div, .form-container select {
    width: 40%;
  }
  #creation_date, #expiration_date {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  
  .buttons-section {
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: stretch;
  }
  
  .buttons-section button {
    font-size: 1.25rem;
  }
  
  button.error {
    background-color: rgba(255, 0, 0, 0.15);
  }
  
  button.success {
    background-color: rgba(0, 255, 0, 0.15);
  }
  
  button.info {
    background-color: rgba(0, 0, 255, 0.15);
  }
  
  .col {
    padding: 15px;
  }
  .row {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr 1fr;
    grid-column-gap: 10px;
    margin: 10px 0;
  }
  .row.header-row {
    background-color: #cccccc;
    font-weight: bold;
  }
  .form-container.inline {
    display: flex;
    flex-direction: column;
  }
  
  .filter-sort {
      margin-top: 30px;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  }
  button {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    border: 1px solid gray;
    padding: 5px 20px;
    margin: 5px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  .flex {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-around;
  align-items: flex-end;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-items {
  width: 150px;
  height: 30px;
  margin: 5px;
  text-align: center;
}
.flex-items2 {
  width: 150px;
  height: 35px;
  margin: 5px;
  text-align: center;
}
  
  /*#emoji-palette >>> .ap, .ql-emojiblot >>> .ap {*/
  /*  background-image: none;*/
  /*  text-indent: 0;*/
  /*  !* It's also possible to adjust spacing. To make selecting emojis easier *!*/
  /*  !* width: 25px; *!*/
  /*  !* height: 25px; *!*/
  /*}*/
  </style>